import { DateTime } from "luxon";
import { DateProvider } from "../interfaces/dateProvider";
import { isNullOrEmpty } from "./null.helper";

export class DateHelper {
    public static isSameDay(date1: DateTime, date2: DateTime): boolean {
        if (date1 == null || !date1.isValid || date2 == null || !date2.isValid) {
            return false;
        }

        return date1.hasSame(date2, "day");
    }

    public static isSameMonth(date1: DateTime, date2: DateTime): boolean {
        if (date1 == null || !date1.isValid || date2 == null || !date2.isValid) {
            return false;
        }

        return date1.hasSame(date2, "month");
    }

    public static isSameYear(date1: DateTime, date2: DateTime): boolean {
        if (date1 == null || !date1.isValid || date2 == null || !date2.isValid) {
            return false;
        }

        return date1.hasSame(date2, "year");
    }

    public static isToday(date: DateTime, dateProvider: DateProvider): boolean {
        if (date == null || !date.isValid) {
            return false;
        }

        return DateHelper.isSameDay(date, dateProvider.now());
    }

    public static tryFromFormat(date: string, format: string) {
        if (isNullOrEmpty(date) || isNullOrEmpty(format)) {
            return null;
        }

        try {
            return DateTime.fromFormat(date, format);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            return null;
        }
    }

    public static tryFromISO(isoDate: string): DateTime {
        if (isNullOrEmpty(isoDate)) {
            return null;
        }

        return DateTime.fromISO(isoDate);
    }

    public static tryToFormat(date: DateTime, format: string): string {
        if (date == null || !date.isValid) {
            return "";
        }

        try {
            return date.toFormat(format);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            return "";
        }
    }

    public static tryToISO(date: DateTime): string {
        if (date == null || !date.isValid) {
            return "";
        }

        try {
            return date.toISO();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            return "";
        }
    }

    public static compare(date1: DateTime, date2: DateTime) {
        let result = 0;
        if (date1 < date2) {
            result = -1;
        }
        if (date1 > date2) {
            result = 1;
        }
        return result;
    }
}
