import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { ScreenOrientation, ScreenOrientationChange } from "@capawesome/capacitor-screen-orientation";
import { GetCurrentOrientationResult } from "@capawesome/capacitor-screen-orientation/dist/esm/definitions";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { AssetsHelper } from "../../helpers/assets.helper";
import { ToastHelper } from "../../helpers/toast.helper";
import { CameraBasePage } from "../../pages/camera.base.page";
import { LoggerService } from "../../services/logs/logger.service";

class Size {
    constructor(public width: number,
                public height: number) {
    }
}

@Component({
    templateUrl: "browserCamera.modal.html",
    styleUrls: ["browserCamera.modal.scss"],
})
export class BrowserCameraModal extends CameraBasePage {
    @ViewChild("content", { static: false }) content: ElementRef;
    @ViewChild("captureCanvas", { static: false }) captureCanvas: ElementRef;
    @ViewChild("videoContainer", { static: false }) videoContainer: ElementRef;
    @ViewChild("video", { static: false }) video: ElementRef;

    @Input() showDebugInfo = false;
    @Input() width = 1000;
    @Input() height = 1000;
    @Input() exportQuality: number = 80;

    templateFilename: string;

    flash = false;
    flashAvailable = false;

    facingModeAvailable = false;

    ready = false;
    takingPhoto = false;

    frontCamera = false;

    cameraSettings: MediaTrackSettings;
    videoConstraints: MediaStreamConstraints = {
        audio: false,
        video: {
            facingMode: "environment",
            width: { min: 600, ideal: 1000, max: 2500 },
            height: { min: 600, ideal: 1000, max: 2500 },
        },
    };
    activeTrack: MediaStreamTrack;

    screenOrientationState: GetCurrentOrientationResult = null;

    constructor(private logger: LoggerService,
                assetsHelper: AssetsHelper,
                private toastHelper: ToastHelper,
                private translateService: TranslateService,
                private viewController: ModalController,
                private changeDetectorRef: ChangeDetectorRef) {
        super(assetsHelper);
    }

    private static hasGetUserMedia() {
        return !!(navigator.mediaDevices?.getUserMedia);
    }

    async ionViewDidEnter() {
        this.ready = false;
        // @ts-ignore
        if (environment.mockCamera || window.playwright) {
            this.dismiss(await this.assetsHelper.readImageAsBase64("default_photo.jpg"));
        } else if (!BrowserCameraModal.hasGetUserMedia()) {
            this.logger.error(this.constructor.name, "getUserMedia() is not supported by your browser");
            this.dismiss(await this.assetsHelper.readImageAsBase64("default_photo.jpg"));
        } else {
            this.screenOrientationState = await ScreenOrientation.getCurrentOrientation();
            void ScreenOrientation.addListener("screenOrientationChange", (orientation: ScreenOrientationChange) => {
                this.logger.info(this.constructor.name, "screenOrientationChange : " + orientation.type);
                this.ready = false;
                this.screenOrientationState = orientation;
                this.changeDetectorRef.detectChanges();
                setTimeout(() => {
                    this.stopCamera();
                    this.startCamera();
                }, 150);
            });

            if (this.width) {
                ((this.videoConstraints.video as MediaTrackConstraints).width as ConstrainULongRange).min = Math.floor(this.width / 1.5);
                ((this.videoConstraints.video as MediaTrackConstraints).width as ConstrainULongRange).ideal = Math.floor(this.width);
                ((this.videoConstraints.video as MediaTrackConstraints).width as ConstrainULongRange).max = Math.floor(this.width * 1.5);
            }
            if (this.height) {
                ((this.videoConstraints.video as MediaTrackConstraints).height as ConstrainULongRange).min = Math.floor(this.height / 1.5);
                ((this.videoConstraints.video as MediaTrackConstraints).height as ConstrainULongRange).ideal = Math.floor(this.height);
                ((this.videoConstraints.video as MediaTrackConstraints).height as ConstrainULongRange).max = Math.floor(this.height * 1.5);
            }
            this.startCamera();

            this.templateFilename = await this.getTemplateFilename(this.photoType, this.inspectionModel);
        }
    }

    public dismiss(data?: any): void {
        this.stopCamera();
        void this.viewController.dismiss(data);
        void ScreenOrientation.removeAllListeners();
    }

    public takePicture(): void {
        this.captureCanvas.nativeElement.width = this.getVideoNativeElement().videoWidth;
        this.captureCanvas.nativeElement.height = this.getVideoNativeElement().videoHeight;
        this.captureCanvas.nativeElement.getContext("2d").drawImage(this.getVideoNativeElement(), 0, 0);

        let dataURL = this.captureCanvas.nativeElement.toDataURL("image/jpeg", this.exportQuality > 1 ? this.exportQuality / 100 : this.exportQuality);
        this.dismiss(dataURL);
    }

    async toggleFlash() {
        this.ready = false;

        this.flash = !this.flash;
        await this.activeTrack.applyConstraints({
            // @ts-ignore
            advanced: [{ torch: this.flash }],
        });

        this.ready = true;
    }

    swapCamera() {
        this.stopCamera();

        this.frontCamera = !this.frontCamera;
        (this.videoConstraints.video as MediaTrackConstraints).facingMode = this.frontCamera ? "user" : "environment";

        this.startCamera();
    }

    private getContentNativeElement() {
        return this.content["el"];
    }

    private getVideoContainerNativeElement() {
        return this.videoContainer.nativeElement;
    }

    private getVideoNativeElement() {
        return this.video.nativeElement;
    }

    private setSizes(cameraSettings: MediaTrackSettings) {
        let contentSize = new Size(this.getContentNativeElement().clientWidth, this.getContentNativeElement().clientHeight);
        this.logger.debug(this.constructor.name, "contentSize : " + JSON.stringify(contentSize));

        this.getVideoContainerNativeElement().style.maxWidth = contentSize.width + "px";
        this.getVideoContainerNativeElement().style.maxHeight = contentSize.height + "px";

        let ratioHeight = cameraSettings.height / contentSize.height;
        this.logger.debug(this.constructor.name, "ratioHeight : " + ratioHeight);
        let containerSizeFromRatioHeight = new Size(Math.floor(cameraSettings.width / ratioHeight), Math.floor(cameraSettings.height / ratioHeight));
        this.logger.debug(this.constructor.name, "containerSizeFromRatioHeight : " + JSON.stringify(containerSizeFromRatioHeight));

        let ratioWidth = cameraSettings.width / contentSize.width;
        this.logger.debug(this.constructor.name, "ratioWidth : " + ratioWidth);
        let containerSizeFromRatioWidth = new Size(Math.floor(cameraSettings.width / ratioWidth), Math.floor(cameraSettings.height / ratioWidth));
        this.logger.debug(this.constructor.name, "containerSizeFromRatioWidth : " + JSON.stringify(containerSizeFromRatioWidth));

        if (containerSizeFromRatioHeight.width <= contentSize.width && containerSizeFromRatioHeight.height <= contentSize.height) {
            this.getVideoContainerNativeElement().style.width = containerSizeFromRatioHeight.width + "px";
            this.getVideoContainerNativeElement().style.height = containerSizeFromRatioHeight.height + "px";
        } else if (containerSizeFromRatioWidth.width <= contentSize.width && containerSizeFromRatioWidth.height <= contentSize.height) {
            this.getVideoContainerNativeElement().style.width = containerSizeFromRatioWidth.width + "px";
            this.getVideoContainerNativeElement().style.height = containerSizeFromRatioWidth.height + "px";
        } else {
            this.getVideoContainerNativeElement().style.width = cameraSettings.width + "px";
            this.getVideoContainerNativeElement().style.height = cameraSettings.height + "px";
        }
    }

    private startCamera() {
        navigator.mediaDevices.getUserMedia(this.videoConstraints)
            .then(mediaStream => {
                this.getVideoNativeElement().srcObject = mediaStream;
                this.getVideoNativeElement().onloadedmetadata = () => {
                    for (const videoTrack of mediaStream.getVideoTracks()) {
                        if (videoTrack.enabled) {
                            this.cameraSettings = videoTrack.getSettings();
                            this.activeTrack = videoTrack;

                            let capabilities = videoTrack.getCapabilities();
                            this.facingModeAvailable = capabilities.facingMode.length > 0;
                            this.flashAvailable = capabilities["torch"];
                        }
                    }

                    this.getVideoNativeElement().play();
                };
                this.getVideoNativeElement().onplaying = () => {
                    this.setSizes(this.cameraSettings);

                    this.ready = true;
                };
            })
            .catch(async (reason) => {
                if (reason.name == "NotAllowedError") {
                    await this.toastHelper.show(this.translateService.instant("IONIC_You_must_give_the_application_permission_to_take_pictures."));
                    this.dismiss();
                } else {
                    this.logger.error(this.constructor.name, reason);
                    this.dismiss(await this.assetsHelper.readImageAsBase64("default_photo.jpg"));
                }
            });
    }

    private stopCamera() {
        this.ready = false;

        let mediaStream = this.getVideoNativeElement().srcObject;
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => track.stop());
        }
    }
}
