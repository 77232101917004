import { LogLevel } from "../gyzmo-commons/interfaces/logLevel";
import { EnvironmentSpec } from "./environment.model.specif";

export const environment: EnvironmentSpec = {
    production: false,
    customer: "WAFA",
    defaultLogin: "VEGA",
    defaultPassword: "VEGAGCSI",
    mockCamera: false,
    mocked: true,
    servers: [
        {
            name: "Gyzmo Dev",
            url: "https://api.irium.software/api-backend-latest",
            apiKey: "e945b510-3432-4281-9801-b3f41a979ca9",
            defaultToken: "KY_MOBILITE",
            token: "KY_MOBILITE",
            context: "GYZMO_DEV",
        },
    ],
    logging: {
        level: LogLevel.DEBUG,
        consoleLogger: { active: true },
        fileLogger: { active: false },
    },
};
