import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { XVEGAID } from "../../../gyzmo-commons/http/header.constant";
import { DATE_NODEJS_FORMAT } from "../../../gyzmo-commons/interfaces/constants";
import { MovementDto } from "../../dto/movement.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { INSPECTION_KINDS } from "../../interfaces/INSPECTION_KINDS";

@Injectable({
    providedIn: "root",
})
export class MovementWsDao extends WsDao<MovementDto> {
    static readonly WS = "movements";
    static readonly WS_INSPECTION_SEARCH = "inspections/movements";

    public getById(serverConnection: ServerConnection, id: string): Promise<MovementDto> {
        return new Promise<MovementDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, MovementWsDao.WS + "/:id", tokens)
                .then(response => {
                    let movement = MovementDto.fromBody(response.body);

                    let promises = [];
                    Promise.all(promises)
                        .then(() => {
                            resolve(movement);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, movement: MovementDto): Promise<MovementDto> {
        return new Promise<MovementDto>((resolve, reject) => {
            let tokens = new Map<string, string>();

            if (!isNullOrEmpty(movement.id)) {
                tokens.set("id", movement.id);

                serverConnection.put(this.constructor.name, MovementWsDao.WS + "/:id", tokens, movement.toBody())
                    .then(response => {
                        resolve(movement);
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            } else {
                delete movement.id;

                serverConnection.post(this.constructor.name, MovementWsDao.WS, tokens, movement.toBody())
                    .then(response => {
                        movement.id = response.headers.get(XVEGAID.toLowerCase());
                        resolve(movement);
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            }
        });
    }

    public getList(serverConnection: ServerConnection, search?: string): Promise<MovementDto[]> {
        return new Promise<MovementDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            let url = MovementWsDao.WS + "/";
            if (search) {
                url += "?_limit=NOLIMIT&search=" + search;
            }

            serverConnection.get(this.constructor.name, url, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve([]);
                    } else {
                        let result = [];
                        (response.body as Array<any>).forEach(value => {
                            let movement = MovementDto.fromBody(value);
                            result.push(movement);
                        });
                        resolve(result);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getListWithoutInspections(serverConnection: ServerConnection,
                                     inspectionKind: string,
                                     startDate: DateTime,
                                     endDate: DateTime,
                                     maxResults: number,
                                     order: string = "ASC",
                                     search?: string): Promise<MovementDto[]> {
        return new Promise<MovementDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            let url = MovementWsDao.WS_INSPECTION_SEARCH;

            let inspectionType = (INSPECTION_KINDS.BACK == inspectionKind) ? "return" : "departure";

            url += "?inspectionType=" + inspectionType + "&showUnproductiveMovements=true" + "&search=" + search + "&_order=" + order;
            if (maxResults > 0) {
                url += "&_limit=" + maxResults;
            } else {
                url += "&_limit=NOLIMIT";
            }

            switch (inspectionKind) {
                case INSPECTION_KINDS.DEPARTURE:
                    url += "&_sort=F570DTDEP";
                    break;
                case INSPECTION_KINDS.BACK:
                    url += "&_sort=F570DTARR";
                    break;
            }

            if (startDate) {
                url += "&startDate=" + startDate.toFormat(DATE_NODEJS_FORMAT);
            }

            if (endDate) {
                url += "&endDate=" + endDate.toFormat(DATE_NODEJS_FORMAT);
            }

            serverConnection.get(this.constructor.name, url, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve([]);
                    } else {
                        let result = [];
                        (response.body as Array<any>).forEach(value => {
                            let movement = MovementDto.fromBody(value);

                            //Le nom des champs différents par rapport aux Movements habituels
                            if (INSPECTION_KINDS.BACK == inspectionKind) {
                                movement.returnDate = value.date;
                            } else {
                                movement.startDate = value.date;
                            }

                            movement.documentNumber = value.bookingNumber;
                            movement.contractNumber = value.contractNumber;
                            result.push(movement);
                        });
                        result.sort((a, b) => {
                            if (INSPECTION_KINDS.BACK == inspectionKind) {
                                if (a.returnDate > b.returnDate) {
                                    return -1;
                                } else if (a.returnDate < b.returnDate) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            } else {
                                if (a.startDate > b.startDate) {
                                    return -1;
                                } else if (a.startDate < b.startDate) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            }
                        });
                        resolve(result);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
