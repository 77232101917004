import { Injectable } from "@angular/core";
import { UserDto } from "../dto/user.dto";
import { AttachmentService } from "./attachment.service";
import { CustomisationService } from "./customisation.service";

@Injectable({ providedIn: "root" })
export class DefaultDataDownloaderService {
    constructor(private customisationService: CustomisationService,
                private attachmentService: AttachmentService) {
    }

    public async onConnectionDefaultData(user: UserDto): Promise<void> {
        let promises = [];

        promises.push(this.customisationService.synchronizeCustomisation(user.company.id));
        promises.push(this.attachmentService.cacheByCompanyId(user.company.id));
        //promises.push(this.attachmentService.cacheByCompanyId("IRIUM"));

        await Promise.all(promises);
    }

    public checkDefaultData(): Promise<{ correctness: boolean; message: string }> {
        return Promise.resolve({ correctness: true, message: "" });
    }
}
