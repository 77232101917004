import { NgModule } from "@angular/core";
import { CacheDbDao } from "../../../gyzmo-commons/dao/db/cache.db.dao";
import { KeyValueDbDao } from "../../../gyzmo-commons/dao/db/keyValue.db.dao";
import { VersionDbDao } from "../../../gyzmo-commons/dao/db/version.db.dao";
import { AddressDbDao } from "./address.db.dao";
import { AttachmentDbDao } from "./attachment.db.dao";
import { ContactDetailsDbDao } from "./contactDetails.db.dao";
import { DriverInfoDbDao } from "./driverInfo.db.dao";
import { EquipmentDbDao } from "./equipment.db.dao";
import { MovementDbDao } from "./movement.db.dao";
import { OfflineModeDbDao } from "./offlineMode.db.dao";
import { SectorDbDao } from "./sector.db.dao";
import { ServerDbDao } from "./server.db.dao";
import { ThirdPartyDbDao } from "./thirdParty.db.dao";
import { UserDbDao } from "./user.db.dao";

@NgModule({
    imports: [],
    providers: [
        AddressDbDao,
        AttachmentDbDao,
        ContactDetailsDbDao,
        DriverInfoDbDao,
        EquipmentDbDao,
        KeyValueDbDao,
        MovementDbDao,
        ServerDbDao,
        ThirdPartyDbDao,
        UserDbDao,
        VersionDbDao,
        OfflineModeDbDao,
        CacheDbDao,
        SectorDbDao,
    ],
})
export class DaoModule {
}
