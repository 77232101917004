import { Injectable } from "@angular/core";
import { Geolocation } from "@capacitor/geolocation";
import { DateTime } from "luxon";
import { DeviceHelper, PlatformName } from "../helpers/device.helper";
import { DateProvider } from "../interfaces/dateProvider";
import { LoggerService } from "./logs/logger.service";

@Injectable({
    providedIn: "root",
})
export class GeolocationService {
    lastCoordinates: { latitude: number, longitude: number, altitude: number } = null;
    lastCoordinatesDate: DateTime = null;
    watchId = "";

    TIMEOUT = 1500;
    MAX_AGE = 30000;

    constructor(private deviceHelper: DeviceHelper,
                private logger: LoggerService,
                private dateProvider: DateProvider) {
    }

    public async initialize() {
        if (this.deviceHelper.isRunningOnDevice()) {
            await Geolocation.requestPermissions({ permissions: ["location", "coarseLocation"] })
                .catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                });
            this.watchId = await Geolocation.watchPosition({ maximumAge: this.MAX_AGE - 1, enableHighAccuracy: true, timeout: this.TIMEOUT },
                (position, err) => {
                    if (err) {
                        this.logger.error(this.constructor.name, err);
                    }

                    if (position) {
                        this.lastCoordinatesDate = this.dateProvider.now();
                        this.lastCoordinates = { latitude: position.coords.latitude, longitude: position.coords.longitude, altitude: position.coords.altitude != null ? position.coords.altitude : 0 };

                        this.logger.debug(this.constructor.name, "Gps coordinates acquired", this.lastCoordinates);
                    }
                });
        }
    }

    public async dispose() {
        await Geolocation.clearWatch({ id: this.watchId });
    }

    public getCurrentPosition(): Promise<{ latitude: number, longitude: number, altitude: number } | null> {
        this.logger.info(this.constructor.name, "Acquiring gps coordinates");

        return new Promise(resolve => {
            if (this.deviceHelper.getPlatform() == PlatformName.BROWSER || this.deviceHelper.getPlatform() == PlatformName.ELECTRON) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position: GeolocationPosition) => {
                            this.lastCoordinatesDate = this.dateProvider.now();
                            this.lastCoordinates = { latitude: position.coords.latitude, longitude: position.coords.longitude, altitude: position.coords.altitude != null ? position.coords.altitude : 0 };

                            this.logger.debug(this.constructor.name, "Gps coordinates acquired", this.lastCoordinates);
                            resolve(this.lastCoordinates);
                        },
                        (positionError: GeolocationPositionError) => {
                            this.logger.error(this.constructor.name, positionError.message);
                            resolve(null);
                        },
                        { maximumAge: this.MAX_AGE - 1, enableHighAccuracy: true, timeout: this.TIMEOUT });
                } else {
                    this.logger.error(this.constructor.name, "Your browser does not support Geolocation!");
                    resolve(null);
                }
            } else {
                resolve(this.lastCoordinates);
            }
        });

    }
}
