import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
    {
        path: "about", loadChildren: () => import("../gyzmo-commons/pages/about/about.module").then(m => m.AboutPageModule),
    },
    {
        path: "home", loadChildren: () => import("./home/home.module").then(m => m.HomeModule),
    },
    {
        path: "cgu", loadChildren: () => import("./account/cgu/cgu.module").then(m => m.CguPageModule),
    },
    {
        path: "privacy-policy", loadChildren: () => import("./account/privacy-policy/privacy-policy.module").then(m => m.PrivacyPolicyPageModule),
    },
    {
        path: "connection", loadChildren: () => import("./account/connection/connection.module").then(m => m.ConnectionPageModule),
    },
    {
        path: "contract-search", loadChildren: () => import("./contract-search/contract-search.module").then(m => m.ContractSearchModule),
    },
    {
        path: "contract-details", loadChildren: () => import("./contract-details/contract-details.module").then(m => m.ContractDetailsPageModule),
    },
    {
        path: "availability-search", loadChildren: () => import("./availability-search/availability-search.module").then(m => m.AvailabilitySearchModule),
    },
    {
        path: "summary", loadChildren: () => import("./summary/summary.module").then(m => m.SummaryPageModule),
    },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            onSameUrlNavigation: "reload",
            initialNavigation: "disabled",
            scrollPositionRestoration: "enabled",
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
