import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { DateHelper } from "../../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../../gyzmo-commons/interfaces/constants";
import { AvailabilityDto } from "../../dto/availability.dto";
import { EquipmentDto } from "../../dto/equipment.dto";
import { LocationDto } from "../../dto/location.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class EquipmentWsDao extends WsDao<EquipmentDto> {
    static readonly WS = "equipments";

    public getById(serverConnection: ServerConnection, id: string): Promise<EquipmentDto> {
        return new Promise<EquipmentDto>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, EquipmentWsDao.WS + "/" + id, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve(new EquipmentDto());
                    } else {
                        let equipment = EquipmentDto.fromBody(response.body);
                        resolve(equipment);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, equipment: EquipmentDto): Promise<EquipmentDto> {
        throw new Error("Not implemented");
    }

    public getList(serverConnection: ServerConnection, search?: string): Promise<EquipmentDto[]> {
        return new Promise<EquipmentDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("search", search);

            let url = EquipmentWsDao.WS + "?_limit=150&_order=F090LIB&search=:search";

            serverConnection.get(this.constructor.name, url, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve([]);
                    } else {
                        let result = [];
                        (response.body as Array<any>).forEach(value => {
                            let equipmentDto = EquipmentDto.fromBody(value);
                            result.push(equipmentDto);
                        });
                        resolve(result);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getAvailabilities(serverConnection: ServerConnection, startDate: DateTime, returnDate: DateTime, startLocation: LocationDto, filter?: string) {
        return new Promise<AvailabilityDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("startDate", DateHelper.tryToFormat(startDate, DATE_NODEJS_FORMAT));
            tokens.set("returnDate", DateHelper.tryToFormat(returnDate, DATE_NODEJS_FORMAT));
            tokens.set("startLocation", startLocation.id);
            tokens.set("filter", filter);

            let url = EquipmentWsDao.WS + "/filtered-availabilities";
            url += "?_limit=150&_order=F090LIB&startDate=:startDate&returnDate=:returnDate&startLocation=:startLocation&filter=:filter";

            serverConnection.get(this.constructor.name, url, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve([]);
                    } else {
                        let result: AvailabilityDto[] = [];
                        (response.body as Array<any>).forEach(value => {
                            let availabilityDto = AvailabilityDto.fromBody(value);
                            result.push(availabilityDto);
                        });
                        resolve(result);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });

    }
}
