import { NotImplementedError } from "../../gyzmo-commons/helpers/notImplementedError";
import { BaseDto } from "./base/base.dto";
import { CategoryDto } from "./category.dto";
import { EquipmentDto } from "./equipment.dto";

export class AvailabilityDto extends BaseDto<void> {
    public category: CategoryDto = null;
    public dispo: boolean = false;
    public equipment: EquipmentDto = null;

    public static fromBody(body: any): AvailabilityDto {
        let availabilityDto = new AvailabilityDto();

        availabilityDto.dispo = (body.dispo == 1);
        availabilityDto.category = CategoryDto.fromBody(body.category);
        availabilityDto.equipment = EquipmentDto.fromBody(body.equipment);

        return availabilityDto;
    }

    public static fromModel(model: void): AvailabilityDto {
        throw new NotImplementedError();
    }

    public toBody(): any {
        const clone: any = { ...this };

        return clone;
    }

    toModel(): void {
        throw new NotImplementedError();
    }
}
