import { NotImplementedError } from "../../gyzmo-commons/helpers/notImplementedError";
import { BaseDto } from "./base/base.dto";

export class CategoryDto extends BaseDto<void> {
    public id: string = "";
    public wording: string = "";

    public static fromBody(body: any): CategoryDto {
        let categoryDto = new CategoryDto();

        categoryDto.id = "" + body.id;
        categoryDto.wording = body.wording;

        return categoryDto;
    }

    public static fromModel(model: void): CategoryDto {
        throw new NotImplementedError();
    }

    public toBody(): any {
        const clone: any = { ...this };

        return clone;
    }

    toModel(): void {
        throw new NotImplementedError();
    }
}
