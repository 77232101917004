import { registerLocaleData } from "@angular/common";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { HTTP } from "@awesome-cordova-plugins/http/ngx";
import { SQLite } from "@awesome-cordova-plugins/sqlite/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DateTime } from "luxon";
import { DaoModule } from "../core/dao/db/dao.module";
import { WsModule } from "../core/dao/ws/ws.module";
import { environment } from "../environments/environment";
import { KeyValueDbDao } from "../gyzmo-commons/dao/db/keyValue.db.dao";
import { DirectivesModule } from "../gyzmo-commons/directives/directives.module";
import { AssetsHelper } from "../gyzmo-commons/helpers/assets.helper";
import { PipesModule } from "../gyzmo-commons/helpers/pipes/pipes.module";
import { AngularHttpClient } from "../gyzmo-commons/http/angularHttpClient";
import { AngularHttpClientWithMock } from "../gyzmo-commons/http/angularHttpClientWithMock";
import { AppNameProvider } from "../gyzmo-commons/interfaces/appName.provider";
import { DateProvider } from "../gyzmo-commons/interfaces/dateProvider";
import { BrowserCameraModalModule } from "../gyzmo-commons/modals/browser-camera/browserCamera.module";
import { DrawModalModule } from "../gyzmo-commons/modals/draw/draw.module";
import { ViewModalModule } from "../gyzmo-commons/modals/view/view.module";
import { MockDateProvider } from "../gyzmo-commons/providers/mockDateProvider";
import { RealDateProvider } from "../gyzmo-commons/providers/realDateProvider";
import { AppVersionService } from "../gyzmo-commons/services/appVersion.service";
import { DatabaseVersionService } from "../gyzmo-commons/services/databaseVersion.service";
import { LoggerService } from "../gyzmo-commons/services/logs/logger.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { APP_OPTIONS } from "./app.constants";
import { PopoversModule } from "./popovers/popovers.module";

registerLocaleData(localeFr);

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

export function createHttpClient(logger: LoggerService,
                                 httpClient: HttpClient,
                                 assetsHelper: AssetsHelper,
                                 keyValueDbDao: KeyValueDbDao) {
    return environment.mocked ? new AngularHttpClientWithMock(logger, httpClient, assetsHelper, keyValueDbDao) : new AngularHttpClient(logger, httpClient);
}

function createDateProvider() {
    return environment.mocked ? new MockDateProvider(DateTime.local(2023, 12, 11, 10, 10)) : new RealDateProvider();
}

function getAppOptions() {
    let appOptions = APP_OPTIONS;

    const currentUrl = window.location.href;
    if (!appOptions["mode"] && currentUrl.endsWith("/#/ios")) {
        console.warn("App forced in ios mode !");
        appOptions.mode = "ios";
    }

    // @ts-ignore
    if (window.playwright) {
        appOptions.animated = false;
    }

    return appOptions;
}

export class DefaultMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        return "~" + params.key;
    }
}

class ImcpAppNameProvider implements AppNameProvider {
    getAppName(): string {
        return "iMobRelay";
    }
}

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        PipesModule,
        DirectivesModule,
        PopoversModule,
        DaoModule,
        WsModule,
        AppRoutingModule,
        BrowserCameraModalModule,
        DrawModalModule,
        ViewModalModule,
        TranslateModule.forRoot({
            defaultLanguage: "en",
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: DefaultMissingTranslationHandler },
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            },
        }),
        IonicModule.forRoot(getAppOptions())], providers: [
        File,
        HTTP,
        SQLite,
        // Pour le test du processus de mise à jour de la structure base
        //{ provide: DatabaseVersionService, useValue: new DatabaseVersionServiceMock("0.0.1") },
        //{ provide: AppVersionService, useValue: new AppVersionServiceMock("99") },
        // Pour la prod
        { provide: DatabaseVersionService, useClass: DatabaseVersionService },
        { provide: AppVersionService, useClass: AppVersionService },
        { provide: AngularHttpClient, useFactory: createHttpClient, deps: [LoggerService, HttpClient, AssetsHelper, KeyValueDbDao] },
        { provide: DateProvider, useFactory: createDateProvider },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: "fr-FR" },
        { provide: AppNameProvider, useValue: new ImcpAppNameProvider() },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {
}
