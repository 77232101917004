import { Component, Input, OnInit } from "@angular/core";
import { AlertController, NavController, PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { UserDto } from "../../../core/dto/user.dto";
import { AttachmentService } from "../../../core/services/attachment.service";
import { UserService } from "../../../core/services/user.service";
import { LoadingHelper } from "../../../gyzmo-commons/helpers/loading.helper";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { BiometryService } from "../../../gyzmo-commons/services/biometry.service";
import { FileService } from "../../../gyzmo-commons/services/file.service";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";

@Component({
    selector: "user-menu",
    templateUrl: "user-menu.popover.html",
    styleUrls: ["user-menu.popover.scss"],
})
export class UserMenuPopover implements OnInit {
    @Input() currentUser: UserDto;

    hasUserManual = true;
    hasCGU = true;
    hasPrivacyPolicy = true;
    isBiometricActive = false;

    constructor(private navController: NavController,
                private logger: LoggerService,
                private userService: UserService,
                private alertController: AlertController,
                private translateService: TranslateService,
                private loadingHelper: LoadingHelper,
                private popoverController: PopoverController,
                private biometryService: BiometryService,
                private attachmentService: AttachmentService,
                private fileService: FileService) {
    }

    async ngOnInit() {
        this.isBiometricActive = (await this.biometryService.isAvailable()).isAvailable && (await this.biometryService.isActive());
        this.hasUserManual = !isNullOrEmpty(await this.attachmentService.getUserManual());
        this.hasCGU = !isNullOrEmpty(await this.attachmentService.getCGU(this.currentUser.company.id));
        this.hasPrivacyPolicy = !isNullOrEmpty(await this.attachmentService.getPrivacyPolicy(this.currentUser.company.id));
    }

    public async userManual() {
        await this.loadingHelper.showLoading();
        let userManual = await this.attachmentService.getUserManual();
        await this.fileService.openDocument(userManual);
        void this.popoverController.getTop().then((element) => {
            void element.dismiss();
        });
        await this.loadingHelper.hideAll();
    }

    public cgu() {
        void this.navController.navigateForward("/cgu", { state: { onlyReading: true } });
        void this.popoverController.getTop().then((element) => {
            void element.dismiss();
        });
    }

    public privacyPolicy() {
        void this.navController.navigateForward("/privacy-policy", { state: { onlyReading: true } });
        void this.popoverController.getTop().then((element) => {
            void element.dismiss();
        });
    }

    public about() {
        void this.navController.navigateForward("/about");
        void this.popoverController.getTop().then((element) => {
            void element.dismiss();
        });
    }

    public disconnect() {
        void this.popoverController.getTop().then((element) => {
            void element.dismiss();
        });

        void this.alertController.create({
            header: this.translateService.instant("IONIC_Logout"),
            message: this.translateService.instant("IONIC_Would_you_like_to_log_out?"),
            buttons: [{
                text: this.translateService.instant("IONIC_Cancel"),
                role: "cancel",
                handler: () => {
                    this.logger.info(this.constructor.name, "Disconnection prevented!");
                },
            },
                {
                    text: this.translateService.instant("IONIC_Confirm"),
                    handler: () => {
                        void this.userService.disconnect(true)
                            .then(() => {
                                void this.navController.navigateRoot("connection");
                            });
                    },
                }],
        }).then(alert => alert.present());
    }

    public async deactivateBiometric() {
        void this.popoverController.getTop().then((element) => {
            void element.dismiss();
        });

        let alert = await this.alertController.create({
            header: this.translateService.instant("IONIC_Deactivate_biometric_login"),
            message: this.translateService.instant("IONIC_Would_you_like_to_deactivate_biometric_login?"),
            buttons: [
                {
                    text: this.translateService.instant("IONIC_No"),
                    role: "cancel",
                },
                {
                    text: this.translateService.instant("IONIC_Yes"),
                    handler: async () => {
                        await this.biometryService.setActive(false);
                    },
                }],
        });
        await alert.present();
    }
}

