import { Injectable } from "@angular/core";
import { CacheDbDao } from "../../gyzmo-commons/dao/db/cache.db.dao";
import { KeyValueDbDao } from "../../gyzmo-commons/dao/db/keyValue.db.dao";
import { VersionDbDao } from "../../gyzmo-commons/dao/db/version.db.dao";
import { AddressDbDao } from "../dao/db/address.db.dao";
import { AttachmentDbDao } from "../dao/db/attachment.db.dao";
import { CompanyDbDao } from "../dao/db/company.db.dao";
import { ContactDetailsDbDao } from "../dao/db/contactDetails.db.dao";
import { DriverInfoDbDao } from "../dao/db/driverInfo.db.dao";
import { EquipmentDbDao } from "../dao/db/equipment.db.dao";
import { LocationDbDao } from "../dao/db/location.db.dao";
import { MovementDbDao } from "../dao/db/movement.db.dao";
import { OfflineModeDbDao } from "../dao/db/offlineMode.db.dao";
import { SectorDbDao } from "../dao/db/sector.db.dao";
import { ServerDbDao } from "../dao/db/server.db.dao";
import { ThirdPartyDbDao } from "../dao/db/thirdParty.db.dao";
import { UserDbDao } from "../dao/db/user.db.dao";

@Injectable({
    providedIn: "root",
})
export class DaoProvider {
    constructor(private addressDbDao: AddressDbDao,
                private contactDetailsDbDao: ContactDetailsDbDao,
                private driverInfoDbDao: DriverInfoDbDao,
                private thirdPartyDbDao: ThirdPartyDbDao,
                private userDbDao: UserDbDao,
                private attachmentDbDao: AttachmentDbDao,
                private equipmentDbDao: EquipmentDbDao,
                private companyDbDao: CompanyDbDao,
                private locationDbDao: LocationDbDao,
                private sectorDbDao: SectorDbDao,
                private movementDbDao: MovementDbDao,
                private keyValueDbDao: KeyValueDbDao,
                private versionDbDao: VersionDbDao,
                private offlineModeDbDao: OfflineModeDbDao,
                private serverDbDao: ServerDbDao,
                private cacheDbDao: CacheDbDao) {
    }

    public getAllDaoList() {
        return [this.keyValueDbDao,
                this.addressDbDao,
                this.contactDetailsDbDao,
                this.driverInfoDbDao,
                this.thirdPartyDbDao,
                this.userDbDao,
                this.attachmentDbDao,
                this.equipmentDbDao,
                this.companyDbDao,
                this.locationDbDao,
                this.sectorDbDao,
                this.movementDbDao,
                this.offlineModeDbDao,
                this.serverDbDao,
                this.cacheDbDao,
                this.versionDbDao];
    }

    public getOnDisconnectDaoList() {
        return [
            this.addressDbDao,
            this.contactDetailsDbDao,
            this.driverInfoDbDao,
            this.thirdPartyDbDao,
            this.userDbDao,
            this.attachmentDbDao,
            this.equipmentDbDao,
            this.companyDbDao,
            this.locationDbDao,
            this.sectorDbDao,
            this.movementDbDao,
            this.offlineModeDbDao];
    }
}
